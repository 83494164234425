<template>
  <vuestic-widget>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Empresa</label>
            <multiselect
              v-model="empresaSelecionada"
              track-by="id"
              label="nome"
              :options="empresas"
              placeholder=""
              selectedLabel="Selecionado"
              select-label="Selecionar Empresa"
              deselect-label="Remover Empresa"
            >
              <span slot="noResult">Empresa não encontrado.</span>
            </multiselect>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                type="date"
                v-model="ouvidoria.prazo"
                id="simple-input"
                required
              >
              <label
                class="select-control-label"
                for="simple-input"
              >Prazo</label>

              <span
                class="alert-danger"
                v-if="erros.chamado"
              >{{erros['setor_do_relatado'][0]}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Area</label>
            <multiselect
              v-model="areaSelecionada"
              track-by="id"
              label="nome"
              :options="areas"
              placeholder=""
              selectedLabel="Selecionado"
              select-label="Selecionar Area"
              deselect-label="Remover Area"
            >
              <span slot="noResult">Area não encontrado.</span>
            </multiselect>

            <span
              class="alert-danger"
              v-if="erros.chamado"
            >{{erros['setor'][0]}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="select-control-label">Area do relatado</label>
            <multiselect
              v-model="areaDoOcorridoSelecionada"
              track-by="id"
              label="nome"
              :options="areas"
              placeholder=""
              selectedLabel="Selecionado"
              select-label="Selecionar Area"
              deselect-label="Remover Area"
            >
              <span slot="noResult">Area não encontrado.</span>
            </multiselect>
            <span
              class="alert-danger"
              v-if="erros.chamado"
            >{{erros['setor_do_relatado'][0]}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <textarea
                v-model="ouvidoria.queixa"
                id="simple-input-call"
              />
              <label
                  for="simple-input-call"
                  class="control-label"
                >queixa</label>
								<span
                  class="alert-danger"
                  v-if="erros.chamado"
                >{{erros['queixa'][0]}}</span>
              </div>
            </div>
				</div>
    </div>
		<div class="row">
          <div class="col d-flex justify-content-end">
             <button class="btn btn-pale btn-micro mr-3" @click="limparCampos()">
              Limpar campos
            </button>
              <button
                class="btn btn-primary btn-micro"
                @click="salvar()"
              >Salvar
              </button>
          </div>
        </div>
	</div>
  </vuestic-widget>
</template>

<script>
export default {
  data() {
    return {
      areas: [],
      empresas: [],
      areaDoOcorridoSelecionada: {},
      areaSelecionada: {},
      empresaSelecionada: {},
      ouvidoria: {
        situacao: 1,
        queixa: '',
        prazo: null,
        setor: null,
        setor_do_relatado: null,
        empresa: null,
      },
      erros: {},
    };
  },
  created() {
    this.getEmpresas();
    this.getAreas();
  },
  methods: {
    getEmpresas() {
      this.$axios.get('api/empresa-list/').then((res) => {
        this.empresas = res.data;
      });
    },
    getAreas() {
      this.$axios.get('api/area-list/').then((res) => {
        this.areas = res.data;
      });
    },
    salvar() {
      this.$axios.post('api/ouvidoria', this.ouvidoria).then(() => {
        this.message('success', 'Ouvidoria enviada');
        this.limparCampos();
      }).catch((err) => {
        this.erros = err.resulte;
      });
    },
    limparCampos() {
      this.areaDoOcorridoSelecionada = {};
      this.areaSelecionada = {};
      this.empresaSelecionada = {};
      this.ouvidoria = {
        situacao: 1,
        queixa: '',
        prazo: null,
        setor: null,
        setor_do_relatado: null,
        empresa: null,
      };
    },
  },
  watch: {
    areaSelecionada(val) {
      this.ouvidoria.setor = val.id;
    },
    areaDoOcorridoSelecionada(val) {
      this.ouvidoria.setor_do_relatado = val.id;
    },
  },
};
</script>

<style>
</style>
